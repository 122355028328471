import httpService from '@/services/httpService';
import quoteService from '@/services/quoteService';

export default {
	name: 'Verify',
	data: function() {
		return {
			styleObject: {},
			showModal: true,
			modalTitle: '',
			current_step: '',
			loading: false,
			info_msg: '',
			code: '',
			phone: null,
			code_error: false,
			showPhoneInput: false,
			phone_retry: '',
			confirm_sent: false,
			text_id: null,
		};
	},
	created: function() {
		this.modalTitle = "What's the code?";
		this.current_step = 'verify';

		const quote = quoteService.getQuoteByType('move');

		this.$log.debug('verify', quote);

		if (!quote.phone) {
			this.$router.push('/');
		}
	},
	beforeRouteLeave: function(to, from, next) {
		if (to.name !== 'Thanks' && to.path !== '/') {
			next(false);
		} else {
			next(true);
		}
	},
	mounted: function() {
		this.phone = quoteService.getPhone();
		this.text_id = localStorage.getItem('tv_text_id');

		if (window.analytics) {
			window.analytics.track('Text_verify', {});
			window.analytics.page(
				'Text_verify',
				{ type: 'page', path: 'Text_verify' },
				{ integrations: { AdWords: false } }
			);
		}
	},
	computed: {
		phoneFormatted: function() {
			if (this.phone) {
				return `(${this.phone.slice(0, 3)}) ${this.phone.slice(
					3,
					6
				)}-${this.phone.slice(6, this.phone.length)}`;
			}

			return '';
		},
	},
	methods: {
		press: function(input) {
			switch (input) {
				case 'init':
					this.code = '';
					break;
				case 'back':
					if (this.code.length) {
						this.code = this.code.slice(0, -1);
					}

					break;
				default:
					if (this.code.length < 4) {
						this.code += input;
					}

					break;
			}
		},
		pressPhone: function(input) {
			const phoneNo = this.phone_retry.replace(/\D+/g, '');

			// this.$log.debug(`|${this.phone_retry}|`, phoneNo);

			switch (input) {
				case 'init':
					this.phone_retry = '';
					break;
				case 'back':
					if (phoneNo.length) {
						let removeSize = -1;
						const regexDash = new RegExp(/-\s$/);
						const regexParens = new RegExp(/\)\s$/);

						if (regexDash.test(this.phone_retry)) {
							removeSize = -4;
						} else if (regexParens.test(this.phone_retry)) {
							removeSize = -3;
						}

						this.phone_retry = this.phone_retry.slice(
							0,
							removeSize
						);
					}

					break;
				default:
					if (phoneNo.length < 10) {
						this.phone_retry += String(input);
					}

					break;
			}
		},
		verifyPhone: function() {
			this.loading = true;

			if (this.code.length === 4) {
				this.loading = true;
				httpService
					.post(`valet/text/validate/${this.code}`, {
						text_id: this.text_id,
					})
					.then(resp => {
						if (!resp.data.success) {
							this.code_error = true;

							setTimeout(() => {
								this.code_error = false;
								this.code = '';
								this.loading = false;
							}, 2500);
						} else {
							localStorage.removeItem('tv_text_id');

							if (window.analytics) {
								window.analytics.track('Text_verified', {});
								window.analytics.page(
									'Thank_You',
									{ type: 'page', path: 'Thank_You' },
									{ integrations: { AdWords: false } }
								);
							}

							this.loading = false;
							quoteService.clearStorage();

							if (process.env.VUE_APP_DEBUG) {
								this.$router.push('/thank-you');
							}

							if (resp.data.hasOwnProperty('destination')) {
								window.location.href = resp.data.destination;
							} else {
								window.location.href
									= 'https://my.topmoving.com/thank-you/'
									+ quoteService.getMoveLeadToken();
							}
						}
					});
			}
		},
		resubmitQuote: function() {
			if (window.analytics && !this.phone_retry.length) {
				window.analytics.page(
					'Resend_code',
					{ type: 'page', path: 'Resend_code' },
					{ integrations: { AdWords: false } }
				);

				window.analytics.track('Resend_code', {});
			}

			quoteService.reSubmitQuote('move').then(resp => {
				this.text_id = resp.data.text_id;
				this.loading = false;
				this.confirm_sent = true;

				setTimeout(() => {
					this.confirm_sent = false;
					this.showPhoneInput = false;
					this.phone_retry = '';
				}, 2500);
			});
		},
		changePhoneAndResubmit: function() {
			const phoneNo = this.phone_retry.replace(/\D+/g, '');

			if (phoneNo.length === 10) {
				this.loading = true;
				this.phone = phoneNo;
				quoteService.setPhone(phoneNo);
				this.resubmitQuote();
			}
		},
		togglePhoneInput: function() {
			this.showPhoneInput = true;

			if (window.analytics) {
				window.analytics.page(
					'Edit_number',
					{ type: 'page', path: 'Edit_number' },
					{ integrations: { AdWords: false } }
				);

				window.analytics.track('Edit_number', {});
			}
		},
	},
	watch: {
		code: function() {
			if (this.code && this.code.length === 4) {
				this.verifyPhone();
			}
		},
		showPhoneInput: function() {
			this.modalTitle = this.showPhoneInput
				? "What's your number?"
				: "What's the code?";
		},
		phone_retry: function() {
			this.changePhoneAndResubmit();
		},
	},
};
